<template>
    <div>
        <el-table :data="tableData" size="mini">
            <el-table-column prop="financialYearId" label="Id" width="100" />
            <el-table-column prop="startdate" label="Startdatum" width="150">
                <template slot-scope="scope">
                    <p>
                        {{ formatDate(scope.row.startDate) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column prop="endDate" label="Slutdatum" width="150">
                <template slot-scope="scope">
                    <p>
                        {{ formatDate(scope.row.endDate) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column prop="annualReportClosed" label="Stängd" width="120" />
            <el-table-column width="150">
                <template slot-scope="scope">
                    <el-tooltip transition="none" effect="light" content="Öppna" placement="top-start" :enterable="false" :open-delay="500">
                        <el-button type="success" plain class="p-1" @click="OpenYear(scope.row.annualReportId)">
                            <i class="fal fa-folder-open text-base" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip transition="none" effect="light" content="Ladda ner årsredovisning" placement="top-start" :enterable="false" :open-delay="500">
                        <el-button type="default" plain class="p-1">
                            <i class="fal fa-download text-base" />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip transition="none" effect="light" content="Radera årsredovisning" placement="top-start" :enterable="false" :open-delay="500">
                        <el-button type="danger" plain class="p-1" @click="deleteModal(scope.row.annualReportId)">
                            <i class="fal fa-trash-can text-base" />
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            annualReportId: undefined,
        };
    },
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },

    methods: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        OpenYear(id) {
            this.annualReportId = id;
            this.$router.push({ path: `/annualReport/companyInformation/${this.annualReportId}` });
        },
        deleteModal(id) {
            this.$emit("deleteModal", id);
        },
    },
};
</script>
